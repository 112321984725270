<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a id="title2">商品订单管理</a></li>
            <li class="breadcrumb-item active" id="title3">商品订单详情</li>
          </ol>
        </div>
        <h4 class="page-title" id="title1">商品订单详情</h4>
      </div>
    </div>
    <el-form :model="formData" label-width="20%" align="left">
      <el-form-item label="订单编号">
        {{ formData.oid }}</el-form-item>
      <el-form-item label="积分商品">
        {{ formData.pname }}</el-form-item>
      <el-form-item label="商品图片" prop="pimage">
        <img :src="'https://www.jiuyihuishou.online/useclothingrecycling/' + formData.by1" style="width: 150px;height: 150px" />
      </el-form-item>
      <el-form-item label="兑换数量">
        {{ formData.oquantity }}</el-form-item>
      <el-form-item label="积分">
        {{ formData.opoint }}</el-form-item>
      <el-form-item label="用户ID">
        {{ formData.mid }}</el-form-item>
      <el-form-item label="兑换时间">
        {{ formData.otime }}</el-form-item>
      <el-form-item label="订单状态">
        {{ formData.ostatus }}</el-form-item>

      <el-form-item label="二维码" prop="pimage">
        <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=' + formData.oid"
          style="width: 150px;height: 150px" />
      </el-form-item>
      <el-form-item>


        <el-button type="info" size="small" @click="back" icon="el-icon-back">返 回</el-button>
      </el-form-item>
    </el-form>


  </div>
</template>
<script>

import request, { base } from "../../../utils/http";
export default {
  name: 'OrdersDetail',
  components: {
  },
  data() {
    return {
      id: '',
      formData: {}, //表单数据         

    };
  },
  created() {
    this.id = this.$route.query.id; //获取参数
    this.getDatas();
  },


  methods: {

    //获取列表数据
    getDatas() {
      let para = {
      };
      this.listLoading = true;
      let url = base + "/orders/get?id=" + this.id;
      request.post(url, para).then((res) => {
        this.formData = JSON.parse(JSON.stringify(res.resdata));
        this.listLoading = false;
      });
    },

    // 返回
    back() {
      //返回上一页
      this.$router.go(-1);
    },

  },
}

</script>
<style scoped></style>
 

